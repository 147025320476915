import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { channelCurrency, formatDate, status, statusBubble } from '../components/Functions'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../providers/AuthProvider'
import Modal from '../components/Modal'
import { useFormik } from "formik";
import * as yup from 'yup';
import FormikInput from '../components/FormikInput'
import { Link } from 'react-router-dom'

export default function Expenses(){
    const navigate = useNavigate()

    const { apiCall } = useContext(AuthContext)

    const [filter, setFilter] = useState('all')
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(null)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [channelFilter, setChannelFilter] = useState('all')
    const [search, setSearch] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [dateRange, setDateRange] = useState(JSON.stringify({from: moment().startOf('month'), to: moment().endOf('month')}))

    const searchRef = useRef()
    const exportRef = useRef()
    
    useEffect(() => {
        if(toggleFilters && searchRef && searchRef.current) searchRef.current.focus() 
    }, [toggleFilters])

    useEffect(() => {
        //Henter inn alle inquiries
        
        getExpenses()
    }, [dateRange])
    
    useEffect(() => {
        if(data) setFilteredData(data)
    }, [data])

    const getExpenses = async () => {
        const range = JSON.parse(dateRange)
        const d = await apiCall({
            action: 'getExpensesByRange',
            from: range.from,
            to: range.to
        })
        if(d.status === 1) setData(d.data)
        //setData([])
    }

    const getDateRange = () => {
        let from, to;
        switch(parseInt(dateRange)){
            case 1: // Departures this month
                from = moment().startOf('month').format('YYYY-MM-DD')
                to = moment().endOf('month').format('YYYY-MM-DD')
                break
            case 2: // Departures next month
                from = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD')
                to = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
                break
            case 3: // Departures previous month
                from = moment().subtract(1, 'M').startOf('month').format('YYYY-MM-DD')
                to = moment().subtract(1, 'M').endOf('month').format('YYYY-MM-DD')
                break
            case 4: // Departures this year
                from = moment().startOf('year').format('YYYY-MM-DD')
                to = moment().endOf('year').format('YYYY-MM-DD')
                break
            default:
                from = moment()
                to = moment()
        }

        return {
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD')
        }
    }

    useEffect(() => {
        var temp

        temp = filterData(data)
        temp = filterChannel(temp)
        if(search.length){
            var condition = search.toLowerCase()
            const searchFilter = temp   .filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if(typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter])

    const filterChannel = (data) => {
        if(channelFilter === 'all') return data 
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterData = (data) => {
        if(filter === 'all') return data 
        return data.filter((a) => a.status === filter)
    }

    const FilterButton = ({label, value}) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    const AddSupplier = () => {

        let schema = yup.object().shape({
            name: yup.string().required('Name required'),
        });
    
        const formik = useFormik({
            initialValues: {
                name: '',
                payment_info: ''
            },
            validationSchema: schema,
            onSubmit: (values) => {  
                newSupplier(values)
            }
        });

        const newSupplier = async (v) => {
            const d = await apiCall({action: 'newSupplier', ...v})
            console.log(d)
            status(d)
            if(d.status === 1) setModalOpen(false)
        }


        return (
            <div style={{width: 400}}>
                <div className="row">
                    <div className="col"><h3>Add supplier</h3></div>
                    <div className="col-auto"><FontAwesomeIcon icon={['far', 'times']} size="xs" className='pointer' onClick={() => setModalOpen(false)} /></div>
                </div>
                
                <form onSubmit={formik.handleSubmit}>
                    <FormikInput
                        name="name"
                        label="Name of supplier"
                        formik={formik}
                    />
                    <FormikInput
                        name="payment_info"
                        label="Payment info"
                        formik={formik}
                    />
                    <button type="submit" className='btn btn-success w-100'>Create supplier</button>
                </form>
            </div>
        )
    }

    const renderDepartureMonths = () => {
        const now = moment()
        const start = moment()
        const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        
        return arr.map((el, key) => {
            start.add(1, 'month')
            return (
                <option key={key} value={JSON.stringify({ 
                    from: start.startOf('month').format('YYYY-MM-DD'),
                    to: start.endOf('month').format('YYYY-MM-DD'),
                })}>Departures {start.format('MMMM')} {start.format('YYYY') !== now.format('YYYY') ? start.format('YYYY') : null}</option>
            )
        })
    }

    const updateStatus = async (status, id) => {
        console.log(`Should update ${id} to ${status}`)

        const d = await apiCall({
            action: 'updateExpenseStatus',
            id,
            status
        })

        if(d.status === 1){
            getExpenses()
        }

    }

    const StatusSelect = ({ status, id }) => {

        

        const change = (s) => {
            
            updateStatus(s, id)
        }

        return (
            <div className={`status-bubble ${status === 'paid' ? 'status-checked' : ''}`}>
                <select value={status} onChange={(e) => change(e.target.value)}>
                    <option value="unpaid">Unpaid</option>
                    <option value="paid">Paid</option>
                </select>
            </div>
        )
    }

    return (
        <div className="p-md-5 p-3">
            <div className="row">
                <div className="col">
                    <h3 className='mb-4'>Expenses</h3>
                    <div className="row mb-4">
                        <div className="col-auto">
                            <div className="small-select">
                                <select onChange={(e) => setDateRange(e.target.value)} defaultValue={JSON.stringify({from: moment().startOf('month'), to: moment().endOf('month')})}>
                                    <option value={JSON.stringify({from: moment().subtract(1, 'M').startOf('month'), to: moment().subtract(1, 'M').endOf('month')})}>Departures previous month</option>
                                    <option value={JSON.stringify({from: moment().startOf('month'), to: moment().endOf('month')})}>Departures this month</option>
                                    {renderDepartureMonths()}
                                    <option value={JSON.stringify({from: moment().startOf('year'), to: moment().endOf('year')})}>Departures this year</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
                    </div>
                </div>
                <div className="col-auto">
                    <button className="btn btn-sm btn-grey" onClick={() => setModalOpen(true)}>Add supplier</button>
                </div>
                <div className="col-auto">
                    <button ref={exportRef} className="btn btn-sm btn-grey">Export</button>
                </div>
            </div>
            
            <Table 
                exportRef={exportRef}
                gridTemplateColumns={["1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr", "1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr", "1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={10}
                rows={filteredData}
                showResult
                unit="expenses"
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                {
                                        [
                                        {label: 'All', value: 'all'},
                                        {label: 'Unpaid', value: 'unpaid'},
                                        {label: 'Paid', value: 'paid'},
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                        <div className='mt-2'>
                            <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>: null}
                    </div>
                }
                headers={[
                    { title: 'Supplier', key: 'name', sort: 'string'},
                    { title: 'Payment info', key: 'payment_info', sort: 'string'},
                    { title: 'Expense', key: 'from_price', sort: 'string', total: true, totalCalculation: () => {
                        const currencies = []
                        const sum = filteredData?.reduce((total, obj) => {
                            if(!currencies.includes(obj.from_currency)) currencies.push(obj.from_currency)
                            return total + obj.from_price
                        }, 0)
                        
                        if(!sum) return 'N/A'
                        
                        return `${currencies.length > 1 ? `(${currencies.join('/')}) ` : currencies[0]} ${sum.toLocaleString('nb-NO')}`
                    }},
                    { title: 'Converted', key: 'price', sort: 'number', total: true},
                    { title: 'Status', key: 'status', sort: 'string'},
                    { title: 'Booking', key: 'nr', sort: 'string'},
                    { title: 'Customer', key: 'customer', sort: 'string'},
                    { title: 'Departure', key: 'departure', sort: 'date'},
                    { title: 'Seller', key: 'seller', sort: 'string'},
                ]}
                customSort={(rows) => {
                    if(rows){
                        const sorted = rows.sort((a, b) => {
                            
                            const a1 =  parseInt(moment(a.departure).format('X'))
                            const b1 = parseInt(moment(b.departure).format('X'))
                            
                            return b1 - a1
                        })

                        
                        return sorted
                    }
                }}
                columns={[
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({name}) => {
                            return name
                        }
                        
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({payment_info}) => {
                            return payment_info
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({from_currency, from_price}) => {        
                                                       
                            return `${from_currency} ${from_price.toLocaleString('nb-NO')}`
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({price, channel}) => {
                            return `${channelCurrency(channel)} ${new Intl.NumberFormat('nb-NO').format(price)}`
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({status, id}) => {
                            //return <div>{statusBubble(status)}</div>
                            return <StatusSelect status={status} id={id} />
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({nr, booking_id }) => {
                            return <Link to={`/bookings/${booking_id}`}>#{nr}</Link>
                        }
                    }
                    ,
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({customer}) => {
                            return <Link to={`/customers/${customer?.id}`}>{customer?.fullname}</Link>
                        }
                    }  
                    ,
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({departure}) => {
                            return formatDate(departure)
                        }
                    }  
                    ,
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({seller}) => {
                            return `${seller?.fullname}`
                        }
                    }  
                ]}
            />


            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
            >
                <AddSupplier />
            </Modal>
        </div>
    )
}

