import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { channelCurrency, statusBubble } from '../components/Functions'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../providers/AuthProvider'

export default function Bookings(){
    const {apiCall, sellers} = useContext(AuthContext)
    const navigate = useNavigate()

    const [filter, setFilter] = useState('all')
    const [sellerFilter, setSellerFilter] = useState('all')
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(data)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [channelFilter, setChannelFilter] = useState('all')
    const [search, setSearch] = useState('')

    const searchRef = useRef()
    const exportRef = useRef()
    
    useEffect(() => {
        if(toggleFilters && searchRef && searchRef.current) searchRef.current.focus() 
    }, [toggleFilters])

    
    useEffect(() => {
        getBookings()
    }, [])

    const getBookings = async () => {
        const d = await apiCall({action: 'getAllBookings'})
        if(d.status === 1) setData(d.data)
    }

    useEffect(() => {
        if(data) setFilteredData(data.sort((a, b) => b.createdon - a.createdon))
    }, [data])

    useEffect(() => {
        var temp

        temp = filterData(data)
        temp = filterChannel(temp)
        temp = filterSeller(temp)

        if(search.length){
            var condition = search.toLowerCase()
            const searchFilter = temp.filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if(!el[obj] || obj === 'seller') return true
                    if(typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                    if(typeof el[obj] === 'object'){
                        Object.keys(el[obj]).forEach((obj2) => {
                            if(typeof el[obj][obj2] === 'string' && el[obj][obj2].toLowerCase().includes(condition)) bool = true
                        })
                    }
                    
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter, sellerFilter])

    const filterChannel = (data) => {
        if(channelFilter === 'all') return data 
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterSeller = (data) => {
        if(sellerFilter === 'all') return data 
        return data.filter((a) => a.seller.id == sellerFilter)
    }

    const filterData = (data) => {
        if(filter === 'all') return data 
        return data.filter((a) => a.status === filter || a.payment_status === filter)
    }

    const FilterButton = ({label, value}) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    return (
        <div className="p-md-5 p-3">
            <div className="row">
                <div className="col">
                <h3 className='mb-4'>Bookings</h3>
                </div>
                <div className="col-auto">
                    <button ref={exportRef} className='btn btn-sm btn-grey'>Export</button>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select mb-3">
                        {
                            sellers ? 
                                <select class="special-select" onChange={(e) => setSellerFilter(e.target.value)}>
                                    <option value="all">All sellers</option>
                                    {  sellers.map((s, k) => <option key={k}  value={s.id}>{s.fullname}</option>) }
                                </select>
                            : null
                        }
                    </div>
                </div>
            </div>
            <Table
                exportRef={exportRef}
                gridTemplateColumns={["1fr 2fr 2fr 2fr 1fr 3fr 2fr 2fr 1fr 1fr", "1fr 2fr 2fr 2fr 1fr 3fr 2fr 2fr 1fr 1fr", "1fr 2fr 2fr 2fr 1fr 3fr 2fr 2fr 1fr 1fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={10}
                rows={filteredData}
                showResult
                unit="offers"
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                    {
                                        [
                                        {label: 'All', value: 'all'},
                                        {label: 'Unopened', value: 'unopened'},
                                        {label: 'No invoice', value: 'no-invoice'},
                                        {label: 'Unpaid', value: 'unpaid'},
                                        {label: 'Paid', value: 'paid'},
                                        {label: 'Unfulfilled', value: 'unfulfilled'},
                                        {label: 'Fulfilled', value: 'fulfilled'},
                                        {label: 'Confirmed', value: 'confirmed'},
                                        {label: 'Ready to go', value: 'ready'}
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                        <div className='mt-2'>
                            <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>: null}
                    </div>
                }
                headers={[
                    { title: 'Booking', key: 'nr'},
                    { title: 'Date', key: 'createdon', sort: 'string'},
                    { title: 'Departure date', key: 'departure', sort: 'string'},
                    { title: 'Customer', key: 'customer', sort: 'string'},
                    { title: 'Channel', key: 'channel', sort: 'string'},
                    { title: 'Offer name', key: 'title', sort: 'string'},
                    { title: 'Total', key: 'price', sort: 'number'},
                    { title: 'Seller', key: 'seller', sort: 'number'},
                    { title: 'Payment status', key: 'payment_status', sort: 'number'},
                    { title: 'Fulfilment', key: 'status', sort: 'number'}
                ]}
                onRowClick={(e) => {
                    navigate(`${e.id}`)
                }}
                customSort={(rows) => {
                    if(rows){
                        const sorted = rows.sort((a, b) => {
                            
                            const a1 =  parseInt(a.id)
                            const b1 = parseInt(b.id)
                            
                            return b1 - a1
                        })

                        
                        return sorted
                    }
                }}
                columns={[
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({ nr }) => {
                            return <div>#{nr}</div>
                        }
                        
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({createdon}) => {
                            
                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(createdon).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({departure}) => {
                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(departure).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({customer}) => {                                    
                            return customer ? customer?.fullname : 'Unknown or deleted'
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({channel}) => {
                            return (<div>{channel}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({title}) => {
                            return (<div>{title}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({ price, channel }) => {
                            return (<div>{price ? `${channelCurrency(channel)} ${new Intl.NumberFormat('nb-NO').format(price)}` : <i>Not set</i>}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({seller}) => {
                            return (<div>{seller.fullname}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({payment_status}) => {
                            return (<div>{statusBubble(payment_status)}</div>)
                        }
                    } ,
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({status}) => {
                            return (<div>{statusBubble(status)}</div>)
                        }
                    }    
                ]}
            />
        </div>
    )
}