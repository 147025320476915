import { createContext, useEffect, useState } from "react";
import axios from 'axios'
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import Login from "../screens/Login";
import { status } from "../components/Functions";
export const AuthContext = createContext({
    accessToken: null,
    user: null
})

export const AuthProvider = ({children}) => {

    const APIURL = 'https://api.safariresor.se/'
    const [accessToken, setAccessToken] = useState(null)
    const [user, setUser] = useState(null)
    const [notifications, setNotifications] = useState(null)
    const [sellers, setSellers] = useState(null)
    
   
    useEffect(() => {
        if(accessToken){
            if(!user){
                getUser()
            }
            if(!sellers){
                getSellers()
            }
        }
    }, [user, accessToken, sellers])

    useEffect(() => {
        

        const token = localStorage.getItem('sr_access_token')
        if(token) setAccessToken(token)
    }, [])

    const getSellers = async () => {
        const d = await apiCall({action: 'getSellerList'})
        if(d.status === 1) setSellers(d.data)
    }

    const reloadNotifications = async () => {
		const d = await apiCall({action: 'getNotifications'})
		setNotifications(d)
	}

    const getUser = async () => {
        const data = await apiCall({action: 'getUserByToken'})
        setUser(data)
    }

    const apiCall = async (data) => {
        
        const status = await axios({
            method: 'post',
            url: `${APIURL}?${data.action}`,
            data: data,
            headers: { 
                "Content-Type": "multipart/form-data",
                Token: `${accessToken}`
            }
        })

        if(status.data.status === 5 || status.data.status === 4){
            //toast.error(status.data.message)
            //localStorage.removeItem('sr_access_token')
            setAccessToken(null)
            setUser(null)
        }

        return status.data
    }

    const downloadAttachment = async (fileObject) => {
        console.log(fileObject, 'download')
        await axios({
            url: `${APIURL}?downloadAttachment`,
            data: {action: 'downloadAttachment', fileData: fileObject},
            method: 'POST',
            responseType: 'blob', // important
            headers: { 
                "Content-Type": "multipart/form-data",
                Token: `${accessToken}`
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileObject.name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    const downloadInvoice = async (fileObject) => {
        console.log(fileObject, 'download')
        await axios({
            url: `${APIURL}?downloadInvoice`,
            data: {action: 'downloadInvoice', fileData: fileObject},
            method: 'POST',
            responseType: 'blob', // important
            headers: { 
                "Content-Type": "multipart/form-data",
                Token: `${accessToken}`
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileObject.name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    const login = async (username, password) => {
        
        const d = await apiCall({
            action: 'login',
            username,
            password
        })

        console.log(d)

        if(d.status !== 1){
            status(d)
        }
        if(d.token){
            localStorage.setItem('sr_access_token', d.token)
            setAccessToken(d.token)
            
        }

        if(d.user){
            setUser(d.user)
        }
    }
    
    return <AuthContext.Provider value={{
        APIURL,
        accessToken,
        user,
        notifications,
        sellers,
        reloadNotifications,
        apiCall,
        downloadAttachment,
        downloadInvoice,
        login,
        logout: () => {
            localStorage.removeItem('sr_access_token')
            setAccessToken(null)
            setUser(null)
        }
    }}>{accessToken && user ? children : <Login />}</AuthContext.Provider>
}