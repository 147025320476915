import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { ago, channelCurrency, channelToCountry, formatDate, statusBubble } from '../components/Functions'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getRandomCustomers, getRandomInquiries } from '../components/FakeApi'
import Modal from '../components/Modal'
import { AuthContext } from '../providers/AuthProvider'
import { useFormik } from "formik";
import * as yup from 'yup';
import FormikInput from '../components/FormikInput'
import { toast } from 'react-toastify'

export default function Customers(){
    const navigate = useNavigate()

    const {apiCall} = useContext(AuthContext)

    const [filter, setFilter] = useState('all')
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(null)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [channelFilter, setChannelFilter] = useState('all')
    const [search, setSearch] = useState('')
    const [modalOpen, setModalOpen] = useState(false)

    const searchRef = useRef()
    const exportRef = useRef()
    
    useEffect(() => {
        if(toggleFilters && searchRef && searchRef.current) searchRef.current.focus() 
    }, [toggleFilters])

    useEffect(() => {
        //Fake API-greie som genererer tilfeldig shit.
        setTimeout(() => {
            //setData(getRandomCustomers())
        }, 500)

        loadCustomers()
    }, [])

    const loadCustomers = async () => {
        const d = await apiCall({action: 'getCustomerList'})

        setData(d.data)
    }

    const createProfile = async (values) => {
        const d = await apiCall({action: 'createProfile', ...values})
                
        if(d.status === 1){
            loadCustomers()
            setModalOpen(false)
            toast.success(d.message)
        }else{
            toast.error(d.message)
        }
    }
    
    useEffect(() => {
        if(data) setFilteredData(data)
    }, [data])

    useEffect(() => {
        var temp
        temp = filterData(data)
        temp = filterChannel(temp)

        if(search.length){
            var condition = search.toLowerCase()
            const searchFilter = temp   .filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if(typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter])

    const filterChannel = (data) => {
        if(channelFilter === 'all') return data 
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterData = (data) => {
        console.log('Should filter', filter)
        if(filter === 'all') return data 
        return data.filter((a) => a.spender)
    }

    const FilterButton = ({label, value}) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    let schema = yup.object().shape({
        channel: yup.string().required('First name required'),
        country: yup.string().required('First name required'),
        first_name: yup.string().required('First name required'),
        last_name: yup.string().required('Last name required'),
        phone: yup.string().required('Phone required'),
    });


    const formik = useFormik({
        initialValues: {
            email: '',
            country: 'SE',
            channel: 'SE',
            first_name: '',
            last_name: '',
            phone: ''
        },
        validationSchema: schema,
        onSubmit: (values) => {  
            createProfile(values)
        },
    });

    return (
        <div className="p-md-5 p-3">
            <div className="row">
                <div className="col">
                    <h3 className='mb-4'>Customers</h3>
                </div>
                <div className="col-auto">
                    <div className="row gx-2">
                        <div className="col-auto">
                            <button ref={exportRef} className='btn btn-grey btn-sm'>Export</button>
                        </div>
                        <div className="col-auto">
                        <button className='btn btn-grey btn-sm btn-primary' onClick={() => setModalOpen(true)}>Create profile</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
            </div>
            <Table
                exportRef={exportRef}
                gridTemplateColumns={["1fr 2fr 2fr 1fr 1fr 2fr", "1fr 2fr 2fr 1fr 1fr 2fr", "1fr 2fr 2fr 1fr 1fr 2fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={10}
                rows={filteredData}
                showResult
                unit="customers"
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                {
                                        [
                                        {label: 'All', value: 'all'},
                                        {label: 'Spenders', value: 'spender'},
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                        <div className='mt-2'>
                            <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>: null}
                    </div>
                }
                headers={[
                    { title: 'Latest activity', key: 'lastlogin', sort: 'string', sortValue: (e) => e ? moment(e).format('X') : 0},
                    { title: 'Name', key: 'fullname', sort: 'string'},
                    { title: 'Location', key: 'channel', sort: 'string'},
                    { title: 'Inquiries', key: 'inquiries', sort: 'number'},
                    { title: 'Orders', key: 'orders', sort: 'number'},
                    { title: 'Amount spent', key: 'amount_spent', sort: 'string'}
                ]}
                onRowClick={(e) => {
                    navigate(`${e.id}`)
                }}
                customSort={(rows) => {
                    if(rows){
                        const sorted = rows.sort((a, b) => {
                            
                            const a1 =  a.lastlogin ? moment(a.lastlogin).format('X') : 0
                            const b1 = b.lastlogin ? moment(b.lastlogin).format('X') : 0
                            return b1 - a1
                        })

                        
                        return sorted
                    }
                }}
                columns={[
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({lastlogin}) => {
                            
                            return (
                                <div>{lastlogin ? ago(lastlogin) : 'Never'}</div>
                            )
                        }
                        
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({fullname}) => {
                            
                            return (
                                <div>{fullname}</div>
                            )
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({channel}) => {                                    
                            return channelToCountry(channel)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({inquiries}) => {
                            return (<div>{inquiries}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({orders}) => {
                            return (<div>{orders}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({amount_spent, channel}) => {
                            return (<div>{amount_spent.toLocaleString('nb-NO')} {channelCurrency(channel)}</div>)
                        }
                    }    
                ]}
            />
            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                containerStyle={{width: 500}}
            >
                <div className="p-3">
                    <div className="row">
                        <div className="col">
                            <h3>Create profile</h3>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-link text-dark" onClick={() => setModalOpen(false)}><FontAwesomeIcon icon={['far', 'times']} size="" /></button>
                        </div>
                    </div>
                <form onSubmit={formik.handleSubmit}>
                <div className="form-group mb-3">
                    <FormikInput
                        name="email"
                        label="Email address"
                        placeholder="Email address"
                        formik={formik}
                        type="email"
                    />
                    </div>
                    <FormikInput 
                        name="country"
                        label="Country"
                        type="select"
                        formik={formik}
                        options={
                            [
                                {label: 'Sweden', value:'SE'},
                                {label: 'Norway', value:'NO'}
                            ]
                        }
                    />
                    <FormikInput 
                        name="channel"
                        label="Channel"
                        type="select"
                        placeholder=""
                        formik={formik}
                        options={
                            [
                                {label: 'Swedish website (SE)', value:'SE'},
                                {label: 'Norwegian website (NO)', value:'NO'}
                            ]
                        }
                    />
                    <div className="row gx-1 mb-3">
                        <div className="col-6">
                            <FormikInput
                                name="first_name"
                                label="First name"
                                formik={formik}
                            />
                        </div>
                        <div className="col-6">
                            <FormikInput
                                name="last_name"
                                label="Last name"
                                formik={formik}
                            />
                        </div>
                    </div>
                    <FormikInput
                        name="phone"
                        label="Phone"
                        formik={formik}
                        placeholder="+## ########"
                    />
                    <button type="submit" className="btn btn-primary w-100">Create profile (send invitation)</button>
                </form>
                </div>
            </Modal>
        </div>
    )
}