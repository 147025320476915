import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { channelCurrency, statusBubble } from '../components/Functions'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../providers/AuthProvider'
import Modal from '../components/Modal'
import FormikInput from '../components/FormikInput'
import { useFormik } from "formik";
import * as yup from 'yup';
import { toast } from 'react-toastify'
import Select from '../components/Select'
export default function Offers(){
    const { apiCall, sellers } = useContext(AuthContext)
    const navigate = useNavigate()

    const [filter, setFilter] = useState('all')
    const [sellerFilter, setSellerFilter] = useState('all')
    const [flagFilter, setFlagFilter] = useState('all')
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(data)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [channelFilter, setChannelFilter] = useState('all')
    const [modalOpen, setModalOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [trips, setTrips] = useState(null)
    const [customers, setCustomers] = useState(null)

    const searchRef = useRef()
    const exportRef = useRef()
    
    useEffect(() => {
        if(toggleFilters && searchRef && searchRef.current) searchRef.current.focus() 
    }, [toggleFilters])

    
    useEffect(() => {
        getOffers()
    }, [])

    useEffect(() => {
        if(data) setFilteredData(data)
    }, [data])

    const getOffers = async () => {
        const d = await apiCall({action: 'getAllOffers'})
        console.log(d.data)
        if(d.status === 1) setData(d.data)
        
    }

    useEffect(() => {
        var temp
        if(!data) return
        temp = filterData(data)
        temp = filterChannel(temp)
        temp = filterSeller(temp)
        temp = filterFlag(temp)
        
        if(search.length){
            var condition = search.toLowerCase()
            const searchFilter = temp.filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if(!el[obj] || obj === 'seller') return true
                    
                    if(typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                    if(typeof el[obj] === 'object'){
                        Object.keys(el[obj]).forEach((obj2) => {
                            if(typeof el[obj][obj2] === 'string' && el[obj][obj2].toLowerCase().includes(condition)) bool = true
                        })
                    }
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter, sellerFilter, flagFilter])

    const filterChannel = (data) => {
        if(channelFilter === 'all') return data 
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterSeller = (data) => {
        
        if(sellerFilter === 'all') return data 
        return data.filter((a) => a.seller.id == sellerFilter)
    }

    const filterFlag = (data) => {
        
        if(flagFilter === 'all') return data 
        return data.filter((a) => a.flagged == flagFilter)
    }

    const filterData = (data) => {
        if(filter === 'all') return data.filter((a) => a.status !== 'not-accepted')
        return data.filter((a) => a.status === filter)
    }

    const FilterButton = ({label, value}) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    const createOffer = async (values) => {
        const d = await apiCall({action: 'createProfile', ...values})
                
        if(d.status === 1){
            
        }
    }

    let schema = yup.object().shape({
        channel: yup.string().required('First name required'),
        country: yup.string().required('First name required'),
        first_name: yup.string().required('First name required'),
        last_name: yup.string().required('Last name required'),
        phone: yup.string().required('Phone required'),
    });


    const formik = useFormik({
        initialValues: {
            email: '',
            country: 'SE',
            channel: 'SE',
            first_name: '',
            last_name: '',
            phone: ''
        },
        validationSchema: schema,
        onSubmit: (values) => {  
            createOffer(values)
        },
    });

    return (
        <div className="p-md-5 p-3">
            <div className="row">
                <div className="col">
                <h3 className='mb-4'>Offers</h3>
                </div>
                <div className="col-auto">
                    <button onClick={() => setModalOpen(true)} className='btn btn-sm btn-primary'>Create offer</button>
                </div>
                <div className="col-auto">
                    <button ref={exportRef} className='btn btn-sm btn-grey'>Export</button>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select mb-3">
                        {
                            sellers ? 
                                <select class="special-select" onChange={(e) => setSellerFilter(e.target.value)}>
                                    <option value="all">All sellers</option>
                                    {  sellers.map((s, k) => <option key={k}  value={s.id}>{s.fullname}</option>) }
                                </select>
                            : null
                        }
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setFlagFilter(e.target.value)}>
                            <option value="all">All marks</option>
                            <option value="1">Flagged</option>
                            <option value="0">Unflagged</option>
                        </select>
                    </div>
                </div>
            </div>
            <Table
                exportRef={exportRef}
                gridTemplateColumns={["1fr 1fr 1fr 1fr 1fr 3fr 2fr 2fr 1fr 1fr", "1fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr 1fr", "1fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr 1fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={10}
                rows={filteredData}
                showResult
                unit="offers"
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                    {
                                        [
                                        {label: 'All', value: 'all'},
                                        {label: 'Unopened', value: 'unopened'},
                                        {label: 'Accepted', value: 'accepted'},
                                        {label: 'Draft', value: 'draft'},
                                        {label: 'For review', value: 'for-review'},  
                                        {label: 'Not accepted', value: 'not-accepted'},  
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                        <div className='mt-2'>
                            <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>: null}
                    </div>
                }
                headers={[
                    
                    { title: 'Offer', key: 'nr', sort: 'string'},
                    { title: 'Latest offer', key: 'createdon', sort: 'string'},
                    { title: 'Departure', key: 'departure', sort: 'date'},
                    { title: 'Customer', key: 'customer', sort: 'string'},
                    { title: 'Channel', key: 'channel', sort: 'string'},
                    { title: 'Offer name', key: 'title', sort: 'string'},
                    { title: 'Total', key: 'price', sort: 'number'},
                    { title: 'Seller', key: 'seller', sort: 'number'},
                    { title: 'Status', key: 'status', sort: 'number'},
                    { title: '', key: 'flagged', sort: 'number'},
                ]}
                onRowClick={(e) => {
                    navigate(`${e.id}`)
                }}
                customSort={(rows) => {
                    if(rows){
                        const sorted = rows.sort((a, b) => {
                            
                            const a1 =  parseInt(moment(a.createdon).format('X'))
                            const b1 = parseInt(moment(b.createdon).format('X'))
                            
                            return b1 - a1
                        })

                        
                        return sorted
                    }
                }}
                columns={[
                   
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({ nr }) => {
                            return <div>#{nr}</div>
                        }
                        
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({createdon}) => {
                            
                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(createdon).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({departure}) => {
                            
                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(departure).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({customer}) => {                                    
                            return customer ? customer?.fullname : <i>Unknown / deleted</i>
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({channel}) => {
                            return (<div>{channel}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({title}) => {
                            return (<div>{title ? title : <i>Not set</i>}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({ price, channel }) => {
                            return (<div>{price ? `${channelCurrency(channel)} ${new Intl.NumberFormat('nb-NO').format(price)}` : <i>Not set</i>}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({seller}) => {
                            return (<div>{seller ? seller.fullname : <i>Not set</i>}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({status}) => {
                            return (<div>{statusBubble(status)}</div>)
                        }
                    },
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({ flagged }) => {
                            return <div className="row justify-content-end me-2"><div className="col-auto"><FontAwesomeIcon  icon={['fas', 'flag']} className={`inactive-flag ${flagged ? 'message-flag-active text-danger' : ''}`} size="lg" /></div></div>
                        }
                        
                    },
                ]}
            />

            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                containerStyle={{width: 500}}
            >
                <CreateOffer 
                    data={data} 
                    setModalOpen={setModalOpen}
                    trips={trips}
                    setTrips={setTrips}
                    customers={customers}
                    setCustomers={setCustomers}
                />   
            </Modal>
        </div>
    )
}

function CreateOffer({ data, setModalOpen, trips, setTrips, customers, setCustomers }){
    const { apiCall } = useContext(AuthContext)

    const navigate = useNavigate()
    
    useEffect(() => {
        if(!trips) loadTrips()
        if(!customers) loadCustomers()
    }, [])

    const loadTrips = async () => {
        const d = await apiCall({action: 'getTrips'})
        if(d.status === 1){
            const temp = []
            d.data.sort((a, b) => a.pagetitle.localeCompare(b.pagetitle)).forEach((t) => {
                temp.push({
                    label: t.pagetitle,
                    value: t.id,
                    selected: 0
                })
            })
            setTrips(temp)
        }
    }

    const loadCustomers = async () => {
        const d = await apiCall({action: 'getCustomers'})
        if(d.status === 1){
            const temp = []
            d.data.sort((a, b) => a.fullname.localeCompare(b.fullname)).forEach((c) => {
                temp.push({
                    label: `${c.fullname} (${c.email})`,
                    value: c.id,
                    selected: 0
                })
            })
            setCustomers(temp)
        }
    }


    let schema = yup.object().shape({
        customer_id: yup.string().required('Customer is required'),
        trip_id: yup.string().required('Trip is required'),
        departure: yup.string().required('Departure date is required'),
    });

    const formik = useFormik({
        initialValues: {
            customer_id: null,
            trip_id: null,
            channel: 'SE',
            adults: 0,
            youth: 0,
            children: 0,
            departure: null
        },
        validationSchema: schema,
        onSubmit: (values) => {  
            newOffer(values)
        }
    });

    const newOffer = async (v) => {
        const d = await apiCall({
            action: 'newOfferWithoutInquiry',
            ...v
        })

        if(d.status === 1){
            toast.success(d.message);
            navigate(`/offers/${d.offer?.id}`)
        }else{
            toast.error(d.message)
        }
    }
    return (
        <>
        <div className="row">
            <div className="col">
                <h3>Create Offer</h3>
            </div>
            <div className="col-auto">
                <button className="btn btn-link text-dark" onClick={() => setModalOpen(false)}><FontAwesomeIcon icon={['far', 'times']} size="" /></button>
            </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <label className='fw-medium'>Send offer to</label>
                <Select 
                    value={formik.values.customer_id}
                    onChange={(value) => formik.setFieldValue('customer_id', value.value)}
                    options={customers}
                    placeholder="Select a customer"
                />
            </div>
            <div className="form-group">
                <label className='fw-medium'>Base offer on</label>
                <Select 
                    value={formik.values.trip_id}
                    onChange={(value) => formik.setFieldValue('trip_id', value.value)}
                    options={trips}
                    placeholder="Select a trip"
                />
            </div>
            <FormikInput 
                name="channel"
                label="Channel"
                type="select"
                placeholder=""
                formik={formik}
                options={
                    [
                        {label: 'Swedish website (SE)', value:'SE'},
                        {label: 'Norwegian website (NO)', value:'NO'}
                    ]
                }
            />
            <div className="row gx-1 mb-3">
                <div className="col-4">
                    <FormikInput
                        name="adults"
                        label="Adults (16+)"
                        formik={formik}
                        type="number"
                    />
                </div>
                <div className="col-4">
                    <FormikInput
                        name="youth"
                        label="Young adults (12-15)"
                        formik={formik}
                        type="number"
                    />
                </div>
                <div className="col-4">
                    <FormikInput
                        name="children"
                        label="Children (0-11)"
                        formik={formik}
                        type="number"
                    />
                </div>
            </div>
            <FormikInput
                name="departure"
                label="Departure date"
                formik={formik}
                type="date"
            />
            <button type="submit" className="btn btn-primary w-100">Create draft</button>
        </form>
        </>
    )
}