import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../providers/AuthProvider'
import { confirm } from "../components/Confirmation";
import { status } from '../components/Functions'

export default function Emails(){
    const navigate = useNavigate()

    const { apiCall } = useContext(AuthContext)

    const [filter, setFilter] = useState('all')
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(null)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [channelFilter, setChannelFilter] = useState('all')
    const [search, setSearch] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [dateRange, setDateRange] = useState(JSON.stringify({from: moment().startOf('month'), to: moment().endOf('month')}))
    const [loading, setLoading] = useState(false)

    const searchRef = useRef()
    const exportRef = useRef()
    
    useEffect(() => {
        if(toggleFilters && searchRef && searchRef.current) searchRef.current.focus() 
    }, [toggleFilters])

    useEffect(() => {
        //Henter inn alle inquiries
        
        getEmailLog()
    }, [])
    
    useEffect(() => {
        if(data) setFilteredData(data)
    }, [data])

    const getEmailLog = async () => {
        setLoading(true)
        const d = await apiCall({
            action: 'getEmailLog'
        })
        if(d.status === 1) setData(d.data)
        setLoading(false)
    }


    useEffect(() => {
        var temp

        temp = filterData(data)
        temp = filterChannel(temp)
        if(search.length){
            var condition = search.toLowerCase()
            const searchFilter = temp.filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if(typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter, data])

    const filterChannel = (data) => {
        if(channelFilter === 'all') return data 
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterData = (data) => {
        if(filter === 'all') return data 
        if(filter === 'sent') return data.filter((a) => a.sent)
        if(filter === 'unsent') return data.filter((a) => !a.sent)
        return data.filter((a) => a.sent === filter)
    }

    const FilterButton = ({label, value}) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    const resendEmail = async (id) => {
        if(await confirm('Resend this email', 'Are you sure you wan\'t to resend this email?', {label: 'Yes', type: 'success'})){
            const d = await apiCall({action: 'resendEmail', id: id})
            getEmailLog()
            status(d)
        }
    }


    return (
        <div className="p-md-5 p-3">
            <div className="row">
                <div className="col">
                    <h3 className='mb-4'>Outgoing emails</h3>
                </div>
                <div className="col-auto">
                    <button ref={exportRef} className="btn btn-sm btn-grey">Export</button>
                </div>
            </div>
            
            <Table 
                exportRef={exportRef}
                gridTemplateColumns={["1fr 2fr 2fr 2fr 1fr 1fr 1fr auto", "1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr", "1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={50}
                rows={filteredData}
                showResult
                unit="emails"
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                {
                                        [
                                        {label: 'All', value: 'all'},
                                        {label: 'Sent', value: 'sent'},
                                        {label: 'Unsent', value: 'unsent'},
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>                                        
                                    </div>
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => getEmailLog()}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'refresh']} spin={loading} /></div>                                        
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                        <div className='mt-2'>
                            <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                            
                        </div>: null}
                    </div>
                }
                headers={[
                    { title: 'Time', key: 'date', sort: 'string'},
                    { title: 'To', key: 'toAddress', sort: 'string'},
                    { title: 'From', key: 'fromAddress', sort: 'string'},
                    { title: 'Subject', key: 'subject', sort: 'string'},
                    { title: 'Sent', key: 'sent', sort: 'boolean'},
                    { title: 'Retries', key: 'retries', sort: 'number'},
                    { title: 'Error', key: 'error', sort: 'string'},
                    { title: 'Actions'}
                ]}
                customSort={(rows) => {
                    if(rows){
                        const sorted = rows.sort((a, b) => {
                            
                            const a1 =  parseInt(moment(a.date).format('X'))
                            const b1 = parseInt(moment(b.date).format('X'))
                            
                            return b1 - a1
                        })

                        
                        return sorted
                    }
                }}
                columns={[
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({date}) => {
                            return new Date(date).toLocaleString('nb')
                        }
                        
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({toAddress}) => {
                            return toAddress.map((el) => <span className='status-block'>{el}</span>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({fromName, fromAddress}) => {        
                                                       
                            return `${fromName} (${fromAddress})`
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({subject}) => {
                            return subject
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({sent}) => {
                            //return <div>{statusBubble(status)}</div>
                            return sent ? <FontAwesomeIcon icon={['fad', 'circle-check']} className='text-success' size='lg' /> : <FontAwesomeIcon icon={['fad', 'circle-xmark']} className='text-danger' size='lg' />
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({retries }) => {
                            return retries === 0 ? <small className='opacity-50'>None</small> : retries
                        }
                    }
                    ,
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({error}) => {
                            if(error?.date){
                                const date = new Date(error.date)
                                return <div>
                                    {date.toLocaleTimeString('nb')} {date.toLocaleDateString('nb')}
                                    <br />
                                    {error.message}
                                </div>
                            }else{
                                return ''
                            }
                            
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({ id }) => {
                            return <button className='filter-button' title='Resend email' onClick={(e) => resendEmail(id)}><FontAwesomeIcon className='pointer' icon={['far', 'repeat-1']} size='lg' /></button>
                        }
                    }
                ]}
            />
        </div>
    )
}

