import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../editor.css'

import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import Link from '@tiptap/extension-link'
import { Editor, EditorContent, EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useCallback, useEffect, useState } from 'react'

const MenuBar = ({linkActive, editor}) => {
  
  if (!editor) {
    return null
  }

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)
  
    // cancelled
    if (url === null) {
      return
    }
  
    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()
  
      return
    }
  
    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }

  return (
    <div className='tiptap-toolbar'>
        <div className="tiptap-toolbar--btn-group">
            
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={!editor.can().chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is-active' : ''}
                    type="button"
                >
                    <FontAwesomeIcon icon={['fal', 'bold']} />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={!editor.can().chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is-active' : ''}
                    type="button"
                >
                    <FontAwesomeIcon icon={[editor.isActive('italic') ? 'fal' : 'fas', 'italic']} />
                </button>
            
      </div>
        <div className="tiptap-toolbar--btn-group">
                <button
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    className={editor.isActive('paragraph') ? 'is-active' : ''}
                    type="button"
                >
                    <FontAwesomeIcon icon={['fal', 'paragraph']} />
                </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                type="button"
            >
                <FontAwesomeIcon icon={['fal', 'h1']} />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                type="button"
            >
                <FontAwesomeIcon icon={['fal', 'h2']} />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                type="button"
            >
                <FontAwesomeIcon icon={['fal', 'h3']} />
            </button>

            
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
                type="button"
            >
                <FontAwesomeIcon icon={['fal', 'h4']} />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
                type="button"
            >
                <FontAwesomeIcon icon={['fal', 'h5']} />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
                type="button"
            >
                <FontAwesomeIcon icon={['fal', 'h6']} />
            </button>
        </div>
        <div className="tiptap-toolbar--btn-group">
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
        type="button"
      >
        <FontAwesomeIcon icon={['fal', 'list-ul']} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
        type="button"
      >
        <FontAwesomeIcon icon={['fal', 'list-ol']} />
      </button>
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()} type="button">
        <FontAwesomeIcon icon={['fal', 'horizontal-rule']} />
      </button>
      </div>
      {linkActive ? 
      <div className="tiptap-toolbar--btn-group">
      <button
        onClick={() => setLink()}
        className={editor.isActive('link') ? 'is-active' : ''}
        type="button"
      >
        <FontAwesomeIcon icon={['fal', 'link']} />
      </button>
      {
        editor.isActive('Link') ? 
        <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
        type="button"
      >
        <FontAwesomeIcon icon={['fal', 'link-slash']} />
      </button>
      :null
      }
      
      </div>
      : null}
    </div>
  )
}
const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
]
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ content, setContent, linkActive, editorRef }) => {
  
	const [initialContent, setInitialContent] = useState(content)

  let editor = useEditor({
    content: initialContent,
    extensions,
	onUpdate: (e) => setContent(e.editor.getHTML())
  })

  

  if(!editor) return null

  if(editorRef){
	editorRef.current = editor
  }

  return (
	<>
		<MenuBar 
			editor={editor} 
			linkActive={linkActive}
		/>
		<EditorContent
			onUpdate={(e) => setContent(e.editor.getHTML())} 
			extensions={extensions}
			editor={editor}
		/>
	</>
  )
}
